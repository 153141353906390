import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { IResponseSuccess } from '@app/core/models/response-sucess.model';
import { ErrorHandlerService } from '@app/core/services/error-handler.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { TranslateService } from '@ngx-translate/core';
import { get } from 'lodash';
import { of, Observable } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { NzModalService } from 'ng-zorro-antd/modal';

import { VatQuotesAdminSettingsService } from '../../services/vat-quotes-admin-settings.service';
import { NotificationService } from '../../ui/services/notification.service';

import * as fromActions from './actions';

@Injectable()
export class VatQuotesAdminSettingsEffects {
  constructor(
    private dataService: VatQuotesAdminSettingsService,
    private actions$: Actions,
    private errorHandler: ErrorHandlerService,
    private router: Router,
    private notifications: NotificationService,
    private translate: TranslateService,
    private modalService: NzModalService,
  ) {}

  load$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadRequest),
      switchMap(({ placeIds }) =>
        this.dataService.load(placeIds).pipe(
          map((response: IResponseSuccess) =>
            fromActions.loadSuccess({
              items: response.data,
            }),
          ),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(fromActions.loadFailure(error));
          }),
        ),
      ),
    ),
  );

  loadSingleItem$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadSingleItemRequest),
      switchMap(({ itemId }) =>
        this.dataService.loadSingleItem(itemId).pipe(
          map((response: IResponseSuccess) =>
            fromActions.loadSingleItemSuccess({
              item: get(response, 'data[0]', {}),
            }),
          ),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(fromActions.loadSingleItemFailure(error));
          }),
        ),
      ),
    ),
  );

  delete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.deleteRequest),
      switchMap(({ itemId }) =>
        this.dataService.delete(itemId).pipe(
          map(() => {
            this.notifications.deleteSuccess('vat_quote');
            return fromActions.deleteSuccess({
              itemId,
            });
          }),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(fromActions.deleteFailure(error));
          }),
        ),
      ),
    ),
  );

  create$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.createRequest),
      switchMap(({ request }) =>
        this.dataService.create(request).pipe(
          map((response: IResponseSuccess) => {
            if (
              response.meta?.status === 'warning' &&
              response.meta?.confirm_required &&
              response.meta?.warnings?.fields
            ) {
              const impactedElements = response.meta.warnings.fields
                .split(',')
                .map((field) => field.trim());
              return fromActions.showCreateWarning({
                warning: response.meta.status,
                impactedElements: impactedElements,
                request: request,
              });
            }

            this.notifications.createSuccess('vat_quote');
            this.router.navigate(['/admin/vat-quotes/' + request.place_id]);
            return fromActions.createSuccess({
              item: response.data[0],
            });
          }),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(fromActions.createFailure(error));
          }),
        ),
      ),
    ),
  );

  update$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.updateRequest),
      switchMap(({ request }) =>
        this.dataService.update(request).pipe(
          map((response: IResponseSuccess) => {
            if (
              response.meta?.status === 'warning' &&
              response.meta?.confirm_required &&
              response.meta?.warnings?.fields
            ) {
              const impactedElements = response.meta.warnings.fields
                .split(',')
                .map((field) => field.trim());
              return fromActions.showUpdateWarning({
                warning: response.meta.status,
                impactedElements: impactedElements,
                request: request,
              });
            }

            this.notifications.updateSuccess('vat_quote');
            return fromActions.updateSuccess({
              item: response.data[0],
            });
          }),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(fromActions.updateFailure(error));
          }),
        ),
      ),
    ),
  );

  handleWarning$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.showCreateWarning, fromActions.showUpdateWarning),
      switchMap(({ impactedElements, request }) => {
        const elementsString = impactedElements.join(', ');
        const isCreate = !('id' in request);

        const modalRef = this.modalService.confirm<'confirm' | 'cancel'>({
          nzTitle: this.translate.instant('vat_quote_warning_title'),
          nzContent: this.translate.instant(
            isCreate ? 'vat_quote_create_warning' : 'vat_quote_update_warning',
            { elements: elementsString },
          ),
          nzOkText: this.translate.instant('confirm'),
          nzCancelText: this.translate.instant('cancel'),
          nzOnOk: () => 'confirm',
          nzOnCancel: () => 'cancel',
        });

        return modalRef.afterClose.pipe(
          map((result: 'confirm' | 'cancel') => {
            if (result === 'cancel') {
              return isCreate
                ? fromActions.createCancelled()
                : fromActions.updateCancelled();
            }

            const forceRequest = {
              ...request,
              force_operation: true,
            };

            return isCreate
              ? fromActions.createRequest({ request: forceRequest })
              : fromActions.updateRequest({ request: forceRequest });
          }),
        );
      }),
    ),
  );
}
