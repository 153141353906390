import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { generateParamArray } from '../core/helpers/params-generator';
import { IVatQuotesAdminSettings } from '../models';
import { IResponseSuccess } from '@app/core/models/response-sucess.model';
@Injectable({
  providedIn: 'root',
})
export class VatQuotesAdminSettingsService {
  constructor(private http: HttpClient) {}

  load(placesId: number[]) {
    return this.http.get(
      `admin/vat_quotes?${generateParamArray('place_id', placesId)}`,
    );
  }

  loadSingleItem(itemId: number) {
    return this.http.get(`admin/vat_quotes?id=${itemId}`);
  }

  create(request: IVatQuotesAdminSettings): Observable<IResponseSuccess> {
    const requestWithForce = {
      ...request,
      force_operation: request.force_operation ?? false,
    };
    return this.http.post<IResponseSuccess>(
      'admin/vat_quote',
      requestWithForce,
    );
  }

  update({
    id,
    ...request
  }: IVatQuotesAdminSettings): Observable<IResponseSuccess> {
    const requestWithForce = {
      ...request,
      force_operation: request.force_operation ?? false,
    };
    return this.http.put<IResponseSuccess>(
      `admin/vat_quote/${id}`,
      requestWithForce,
    );
  }

  delete(id: number) {
    return this.http.delete(`admin/vat_quote/${id}`);
  }
}
