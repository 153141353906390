import { createAction, props } from '@ngrx/store';

import {
  BookingWidget,
  CreateBookingWidgetRequest,
  IVatQuotesAdminSettings,
  UpdateBookingWidgetRequest,
} from '../../models';

export const loadRequest = createAction(
  '[Vat Quotes Admin Setting] Load Request',
  props<{
    placeIds: number[];
  }>(),
);

export const loadSuccess = createAction(
  '[Vat Quotes Admin Setting] Load Success',
  props<{ items: IVatQuotesAdminSettings[] }>(),
);

export const loadFailure = createAction(
  '[Vat Quotes Admin Setting] Load Failure',
  props<{ error: any }>(),
);

export const loadSingleItemRequest = createAction(
  '[Vat Quotes Admin Setting] Load Single Item Request',
  props<{
    itemId: number;
  }>(),
);

export const loadSingleItemSuccess = createAction(
  '[Vat Quotes Admin Setting] Load Single Item Success',
  props<{ item: IVatQuotesAdminSettings }>(),
);

export const loadSingleItemFailure = createAction(
  '[Vat Quotes Admin Setting] Load Single Item Failure',
  props<{ error: any }>(),
);

export const deleteRequest = createAction(
  '[Vat Quotes Admin Setting] Delete Request',
  props<{ itemId: number }>(),
);

export const deleteSuccess = createAction(
  '[Vat Quotes Admin Setting] Delete Success',
  props<{ itemId: number }>(),
);

export const deleteFailure = createAction(
  '[Vat Quotes Admin Setting] Load Failure',
  props<{ error: any }>(),
);

export const createRequest = createAction(
  '[Vat Quotes Admin Setting] Create Request',
  props<{ request: IVatQuotesAdminSettings }>(),
);

export const createSuccess = createAction(
  '[Vat Quotes Admin Setting] Create Success',
  props<{ item: IVatQuotesAdminSettings }>(),
);

export const createFailure = createAction(
  '[Vat Quotes Admin Setting] Create Failure',
  props<{ error: any }>(),
);

export const updateRequest = createAction(
  '[Vat Quotes Admin Setting] Update Request',
  props<{ request: IVatQuotesAdminSettings }>(),
);

export const updateSuccess = createAction(
  '[Vat Quotes Admin Setting] Update Success',
  props<{ item: IVatQuotesAdminSettings }>(),
);

export const updateFailure = createAction(
  '[Vat Quotes Admin Setting] Update Failure',
  props<{ error: any }>(),
);

export const resetState = createAction(
  '[Vat Quotes Admin Setting] Reset State',
);

export const showCreateWarning = createAction(
  '[VatQuotesAdminSettings] Show Create Warning',
  props<{
    warning: string;
    impactedElements: string[];
    request: IVatQuotesAdminSettings;
  }>(),
);

export const createCancelled = createAction(
  '[VatQuotesAdminSettings] Create Cancelled',
);

export const showUpdateWarning = createAction(
  '[VatQuotesAdminSettings] Show Update Warning',
  props<{
    warning: string;
    impactedElements: string[];
    request: IVatQuotesAdminSettings;
  }>(),
);

export const updateCancelled = createAction(
  '[VatQuotesAdminSettings] Update Cancelled',
);
